import React from "react";
import styled from "@emotion/styled";
import scrollTo from "gatsby-plugin-smoothscroll";

const MenuContainer = styled.ul`
  list-style: none;
  display: flex;
  flex-flow: row nowrap;
  padding: 0;
  margin: 0;
  li {
    padding: 0;
    margin: 0;
  }
  @media (max-width: 768px) {
    flex-flow: column nowrap;
    background-color: #f0f0f0;

    opacity: 90%;

    position: fixed;
    transform: ${({ open }) => (open ? "translateX(0)" : "translateX(100%)")};
    top: 0;
    right: 0;
    height: 100vh;
    width: 100vw;
    padding-top: 3.5rem;

    text-align: center;
    li {
      margin-bottom: 25px;
    }
  }
`;

const StyledLink = styled.a`
  font-family: "Gotham Bold";
  font-size: 15px;
  color: #303030;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
  margin: 0 10px;
  &:hover {
    color: ${props => props.theme.colors.red};
    transition: color 0.3s;
  }

  @media (max-width: 768px) {
    font-size: 30px;
  }
`;

const sections = [
  { href: "offer", label: "oferta" },
  { href: "about", label: "o nas" },
  { href: "contact", label: "kontakt" },
];

export default function Menu({ open, setOpen }) {
  return (
    <MenuContainer open={open}>
      {sections.map(({ href, label }) => {
        return (
          <li key={href}>
            <StyledLink
              onClick={() => {
                scrollTo(`#${href}`);
                setOpen(!open);
              }}
            >
              {label}
            </StyledLink>
          </li>
        );
      })}
    </MenuContainer>
  );
}
