import { css } from "@emotion/react";

export const globalStyles = css`
  @import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;1,100;1,300;1,400&display=swap");

  #root {
    display: flex;
  }

  body {
    max-width: 100vw;
  }

  * {
    box-sizing: border-box;
  }

  h1 {
    font-family: "Gotham Bold";
  }
`;

export const theme = {
  colors: {
    red: "#e32117",
  },
  paragraphFont: {
    family: "Lato",
    size: '16px',
    color: '#303030',
  }
};
