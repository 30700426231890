import React from "react";
import Burger from "./burger";
import styled from "@emotion/styled";
import Img from "gatsby-image";
import { useStaticQuery, graphql } from "gatsby";

const Nav = styled.nav`
  width: 1200px;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .logo {
    width: 230px;
    img {
      margin: 0;
    }
  }

  @media (max-width: 1210px) {
    margin: 0 20px;
  }

  @media (max-width: 768px) {
    height: 60px;
  }

  transition: height 0.2s;
`;

const NavLayout = styled.div`
  width: 100%;
  height: 80px;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  z-index: 10000;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2);

  @media (max-width: 768px) {
    height: 60px;
  }
`;

const Logo = styled.div`
  width: 230px;
  height: auto;
  @media (max-width: 768px) {
    width: 180px;
    height: auto;
  }
  .menu-logo {
    margin: 0;
  }

  transition: width 0.2s;
`;

export default function Navbar() {
  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "qusec-menu-logo.png" }) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            src
            srcSet
            sizes
          }
        }
      }
    }
  `);

  return (
    <NavLayout id="top">
      <Nav>
        <span
          style={{ cursor: "pointer" }}
          onClick={() => window[`scrollTo`]({ top: 0, behavior: `smooth` })}
        >
          <Logo>
            <Img
              className="menu-logo"
              fluid={data.file.childImageSharp.fluid}
              alt="A corgi smiling happily"
            />
          </Logo>
        </span>
        <Burger />
      </Nav>
    </NavLayout>
  );
}
