import React from "react";
import { Global, ThemeProvider } from "@emotion/react";
import { globalStyles, theme } from "../../theme/theme";
import "./layout.css";

import NavBar from "../navbar/navbar";

const Layout = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <Global styles={globalStyles} />
      <NavBar id="top" />
      {children}
    </ThemeProvider>
  );
};

export default Layout;
